import axios from 'axios';

export async function GetSignInStatistics(queryinfo){
    var res = await axios.get('Schedule/v1/SignInStatistics', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}