<template>
    <div class="datapage-div">
        <el-card class="el-card-main">
            <el-row class="row-input-div" :gutter="10">
                <el-col :span="4">
                    <el-input placeholder="请输入学生姓名" v-model="QueryInfo.StudentName" clearable @clear="StudentSignIn"> 
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入课程主题" v-model="QueryInfo.ContentName" clearable @clear="StudentSignIn"> 
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-select class=".plane_select" placeholder="选择类型" 
                        v-model="QueryInfo.Tag"
                        @change="LevelHandleChange"
                        clearable>
                        <el-option 
                        v-for="item in TagList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4"> 
                    <el-cascader
                    v-model="QueryInfo.CourseID"
                    placeholder="选择课程"
                    :options="CourseCFAndCourse"
                    @change="CourseCFAndCourseHandleChange"
                    :props="CascaderProps"
                    clearable>
                    </el-cascader>
                </el-col>
                <el-col :span="4">
                    <el-date-picker
                    v-model="QueryInfo.Date"
                    type="month"
                    placeholder="选择签到月份"
                    format="yyyy-MM"
                    @change="DatePickerChange($event)">
                    </el-date-picker>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="StudentSignIn">查询</el-button>
                </el-col>
            </el-row>
            <el-row class="row-data-div" :gutter="10">
                <el-col :span="16">
                    <el-card>
                        <el-table :data="StudentSignInList" v-loading="Loading" height="66vh">
                            <el-table-column label="#" type="index"></el-table-column>
                            <el-table-column label="学生姓名" prop="studentName"></el-table-column>
                            <el-table-column label="课程名称" prop="courseName"></el-table-column>
                            <el-table-column label="主题" prop="titleName"></el-table-column>
                            <el-table-column label="类型">
                                <template slot-scope="scope"> 
                                    <el-tag type="success" v-if="scope.row.level === 1" disable-transitions>
                                        签到
                                    </el-tag>
                                    <el-tag type="warning" v-if="scope.row.level === 2" disable-transitions>
                                        补签
                                    </el-tag>
                                    <el-tag type="danger" v-if="scope.row.level === 3" disable-transitions>
                                        请假
                                    </el-tag>
                                    <el-tag type="danger" v-if="scope.row.level === 4" disable-transitions>
                                        未操作
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="签到时间" prop="signTime"></el-table-column>
                            <el-table-column label="补签时间" prop="supSignTime"></el-table-column>
                            <div slot="empty">
                                <el-empty description="暂无数据！" />
                            </div>
                        </el-table>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div ref="pschart" class="chart_div">
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { GetQueryStudentSignIn } from '@/assets/js/api/scheduleapi';
import { GetCourseClassFicationAndCourse } from '@/assets/js/api/courseclassficationapi' ;
import { GetSignInStatistics } from '@/assets/js/api/statisticsapi';
import * as echarts from 'echarts';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    computed:{
        StudentSignDialogListVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            spreadCount:SpreadCount,
            StudentSignInList:[],
            QueryInfo:{
                Start:0,
                Count:SpreadCount[0],
                Date:new Date()
            },
            Total: 0,
            Loading:false,
            CascaderProps:{
                value:'id',
                label:'name',
                children:'children'
            },
            CourseCFAndCourse:[],
            // 1：签到；2：补签；3：请假；4：未操作
            TagList:[
                { 'id': 1, 'name':"签到" },
                { 'id': 2, 'name':"补签" },
                { 'id': 3, 'name':"请假" },
                { 'id': 4, 'name':"未操作" }
            ],
            Statistics: []
        }
    },
    mounted(){
        this.StudentSignIn()
        this.CourseClassFicationAndCourse()
    },
    methods:{
        async CourseClassFicationAndCourse(){
            var ret = await GetCourseClassFicationAndCourse()
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.CourseCFAndCourse = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async SignInStatistics(){
            var ret = await GetSignInStatistics(this.QueryInfo)
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.Statistics = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.$nextTick(function () {
                this.getChart();
            }, 1000);
        },
        CourseCFAndCourseHandleChange(val){
            if(val.length !== 0){
                this.QueryInfo.CourseClassFicationID = val[0]
                this.QueryInfo.CourseID = val[1]
            }
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.StudentSignIn()
        },
        LevelHandleChange(val){
            if(val === null||val === '')
            {
                this.QueryInfo.Level = 0
            }
            else{
                this.QueryInfo.Level = val
            }
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.StudentSignIn()
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.StudentSignIn()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.StudentSignIn()
        },
        async DatePickerChange(){
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.Loading = true
            var ret = await GetQueryStudentSignIn(this.QueryInfo)
             this.SignInStatistics()
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.StudentSignInList = ret.data.signInfos
                this.Total = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            } 
           
        },
        async StudentSignIn(){
            this.Loading = true
            var ret = await GetQueryStudentSignIn(this.QueryInfo)
            this.SignInStatistics()
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.StudentSignInList = ret.data.signInfos
                this.Total = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
            
        },
        getChart(){
            var option = {
                title: {
                    text: '操作占比',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '操作类型',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                            formatter: function(params) {
                                var total = 0; // 定义一个变量存储总数
                                var data = option.series[0].data; // 获取数据源
                                for (var i = 0; i < data.length; i++) {
                                    total += data[i].value; // 计算总数
                                }
                                var percent = ((params.value / total) * 100).toFixed(2); // 计算百分比
                                return percent + '%'; // 格式化字符串
                            },
                        },
                        emphasis: {
                            label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                    }
                ]
            };
            option.series[0].data = this.Statistics
            if (this.$refs.pschart == null) {
                return
            }
            echarts.dispose(this.$refs.pschart)
            var myChart  = echarts.init(this.$refs.pschart)
            option && myChart.setOption(option);
        }
    }
}
</script>