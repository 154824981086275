import axios from 'axios';

//查询课程表
export async function GetQuerySchedules(queryinfo){
var res = await axios.get('Schedule/v1/Schedules', {params: queryinfo})
.then(function (response) {
    return response.data.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//提交课程表
export async function PostScheduleInfo(scheduleinfo){
var res = await axios.post('Schedule/v1/Schedule', scheduleinfo)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//获取排课课程表
export async function GetSchedulesInfo(queryinfo){
var res = await axios.get('Schedule/v1/ScheduleByParams',  {params: queryinfo})
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//获取排课课程表
export async function GetSchedulesInfo2(queryinfo){
var res = await axios.get('Schedule/v1/ScheduleByParams2',  {params: queryinfo})
.then(function(response){
    return response.data.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//获取节次
export async function GetScheduleTime(){
var res = await axios.get('Schedule/v1/ScheduleTime')
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}


//查询学生排课课程表
export async function GetStudentScheduleArrangement(queryinfo){
var res = await axios.get('Schedule/v1/StudentScheduleArrangement', {params: queryinfo})
.then(function (response) {
    return response.data.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//提交排课信息
export async function PostStudentSchedule(studentschedule){
var res = await axios.post('Schedule/v1/StudentSchedule', studentschedule)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//删除学生排课信息
export async function DeleteStudentSchedule(studentschedule){
var res = await axios.delete('Schedule/v1/StudentSchedule', { params: studentschedule })
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}



//删除排课信息
export async function DeleteSchedule(id){
var res = await axios.delete('Schedule/v1/Schedule', { params: {id : id} })
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}



//提交签到信息
export async function PostStudentSignInInfo(signininfo){
var res = await axios.post('Schedule/v1/StudentSign', signininfo)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//提交签到信息列表
export async function PostStudentsSignInInfo(signininfos){
var res = await axios.post('Schedule/v1/StudentsSign', signininfos)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//获取学生的签到表
export async function GetStudentSignForm(id){
var res = await axios.get('Schedule/v1/StudentSignForm', { params: { StudentID:id}})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//按日期查询学生签到记录
export async function GetQueryStudentSignIn(queryinfo){
var res = await axios.get('Schedule/v1/QueryStudentSignForm', {params: queryinfo})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//删除学生签到信息
export async function DeleteStudentSignInInfo(info){
var res = await axios.delete('Schedule/v1/StudentSignIn',{ params: { StudentID:info.studentid, ID:info.id, level:info.level}})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//修改签到课程的效果评价
export async function PutSignScheduleInfo(ScheduleInfo){
var res = await axios.put('Schedule/v1/SignScheduleContent', ScheduleInfo)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//获取签到课程内容
export async function GetSignScheduleInfo(id){
var res = await axios.get('Schedule/v1/SignScheduleContent', { params: { SignID:id}})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//根据ID获取课表
export async function GetScheduleByID(id){
var res = await axios.get('Schedule/v1/ScheduleByID',{ params: { id:id}})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}

//修改课程表内容
export async function PutSchedule(scheduleinfo){
var res = await axios.put('Schedule/v1/Schedule', scheduleinfo)
.then(function(response){
    return response.data;
})
.catch(function (error){
    console.log(error);
    return null;
});
return res;
}

//根据ID获取详细课表
export async function GetDetailScheduleByID(id){
var res = await axios.get('Schedule/v1/DetailScheduleByID',{ params: { id:id}})
.then(function (response) {
    return response.data;
})
.catch(function (error) {
    console.log(error);
    return null;
});
return res;
}